import React from 'react'

const MissingPage = () => {
  return (
    <div>
        <h3>Oppss. Page not  Found</h3>
    </div>
  )
}

export default MissingPage